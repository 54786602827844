// http://ionicframework.com/docs/theming/
@import "~@ionic/core/css/core.css";
@import "~@ionic/core/css/normalize.css";
@import "~@ionic/core/css/structure.css";
@import "~@ionic/core/css/typography.css";

@import "~@ionic/core/css/padding.css";
@import "~@ionic/core/css/float-elements.css";
@import "~@ionic/core/css/text-alignment.css";
@import "~@ionic/core/css/text-transformation.css";
@import "~@ionic/core/css/flex-utils.css";
@import "~react-circular-progressbar/dist/styles.css";

ion-modal {
  z-index: 1000 !important;
}

.video-fullscreen {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  background: black;
}

.modal-fullscreen .modal-wrapper,
.modal-fullscreen .swipper-container {
  width: 100%;
  height: 100%;
}

.error-toast {
  margin-top: env(safe-area-inset-top);
  background: var(--ion-color-danger, #f04141) !important;
}

.error-toast-download {
  background: var(--ion-color-danger, #f04141) !important;
  font-size: 0.8rem;
  white-space: pre-line;
  word-break: break-all;
}

.info-toast {
  margin-top: env(safe-area-inset-top);
  background: var(--ion-color-primary, #3880ff) !important;
}

.pic-descr {
  h2 {
    text-align: center;
    font-size: 3.5rem;
    padding: 1rem;
  }
}

.ddcontainersource {
  perspective: 1000px;
}

.formatted-input{
  font-size: 2rem;
  margin: 1em 0;
  height: 3.5rem;
  width: 100%;
  text-align: center;
}

@-webkit-keyframes pulse {
  0% { -webkit-transform: scale(1); }
  50% { -webkit-transform: scale(1.2); }
  100% { -webkit-transform: scale(1); }
  }

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.pulsating-circle {
  animation: pulse 1.5s infinite;
  animation-direction: alternate;
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@keyframes fade-out-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fade-out-animation ease 1s forwards;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

ion-title.title-large {
  text-transform: uppercase;
  font-size: 1.8rem;
}

.dev {
  ion-toolbar {
    --background: #ff023d;
  }
}

.stage {
  ion-toolbar {
    --background: #629e3d;
  }
}
