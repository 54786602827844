@font-face {
  font-family: "DINPro Medium";
  font-style: normal;
  font-weight: normal;
  src: local("DINPro Medium"), url("./font/DINPro-Medium.otf") format("opentype");
}

@font-face {
  font-family: "DINPro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("DINPro Bold"), url("./font/DINPro-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("DINPro Bold"), url("./font/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: local("DINPro Bold"), url("./font/OpenSans-Bold.ttf") format("truetype");
}
